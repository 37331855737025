var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('van-nav-bar',{attrs:{"left-arrow":"","title":_vm.$t('tbm.考勤详情'),"fixed":"","right-text":_vm.showDate},on:{"click-left":_vm.back}}),_c('div',{staticClass:"selfServiceDetail",staticStyle:{"padding-top":"46px"}},[_c('p',{style:({ color: _vm.showAbnormal ? 'rgb(67, 120, 190)' : '#646566' }),on:{"click":_vm.handleAbnormal}},[_c('i',{staticClass:"iconfont icon-shaixuan",staticStyle:{"margin-right":"10px"}}),_vm._v(_vm._s(_vm.$t('tbm.只看异常'))+" ")]),_c('div',{staticStyle:{"margin-top":"40px"}},_vm._l((_vm.dataList),function(item,index){return _c('div',{key:index},[_c('div',[_c('p',[_c('label',[_vm._v(_vm._s(_vm.$t('tbm.日期')))]),_c('span',[_vm._v(_vm._s(item.dateDetail))])]),_c('p',[_c('label',[_vm._v(_vm._s(_vm.$t('tbm.签到时间')))]),_c('span',[_vm._v(_vm._s(item.signStartTime || '--'))])]),_c('p',[_c('label',[_vm._v(_vm._s(_vm.$t('tbm.签退时间')))]),_c('span',[_vm._v(_vm._s(item.signStartTime == item.signEndTime && item.signStartTime ? '--' : item.signEndTime || '--'))])]),(_vm.currentDate == item.dateDetail)?_c('div',[_c('p',[_c('label',[_vm._v(_vm._s(_vm.$t('tbm.状态')))]),_c('span',{style:({
                  color:
                    item.dataFlag &&
                    (item.dataFlag.indexOf('迟到') != -1 ||
                      item.dataFlag.indexOf('早退') != -1 ||
                      item.dataFlag.indexOf('未签') != -1)
                      ? 'rgb(240, 45, 45)'
                      : ''
                })},[_vm._v(" "+_vm._s(_vm.strReplace(item.dataFlag))+" ")])])]):_vm._e(),(_vm.currentDate != item.dateDetail)?_c('div',[_c('p',[_c('label',[_vm._v(_vm._s(_vm.$t('tbm.状态')))]),_c('span',{style:({
                  color:
                    item.dataFlag &&
                    (item.dataFlag.indexOf('迟到') != -1 ||
                      item.dataFlag.indexOf('早退') != -1 ||
                      item.dataFlag.indexOf('未签') != -1)
                      ? 'rgb(240, 45, 45)'
                      : ''
                })},[_vm._v(" "+_vm._s(item.dataFlag)+" ")])])]):_vm._e()]),(_vm.currentTime > item.dateDetail)?_c('div',[_c('p',{style:({
              color:
                (!item.signStartTime && !item.signEndTime) || (item.dataFlag && item.dataFlag.indexOf('休息') != -1)
                  ? 'rgb(153, 153, 153)'
                  : 'rgb(67, 120, 190)',
              borderColor:
                (!item.signStartTime && !item.signEndTime) || (item.dataFlag && item.dataFlag.indexOf('休息') != -1)
                  ? 'rgb(153, 153, 153)'
                  : 'rgb(67, 120, 190)'
            }),on:{"click":function($event){return _vm.goRouter(item)}}},[_vm._v(_vm._s(_vm.$t('tbm.打卡详情'))+" ")]),(item.attendanceResult !== '0' && item.dataFlag !== '0')?_c('p',{on:{"click":function($event){_vm.showUnusual = true}}},[_vm._v(_vm._s(_vm.$t('tbm.异常处理')))]):_vm._e(),_c('van-popup',{attrs:{"position":"bottom"},model:{value:(_vm.showUnusual),callback:function ($$v) {_vm.showUnusual=$$v},expression:"showUnusual"}},[_c('van-picker',{attrs:{"show-toolbar":"","columns":_vm.unusualList},on:{"confirm":_vm.onUnusualListConfirm,"cancel":function($event){_vm.showUnusual = false}}})],1)],1):_vm._e()])}),0)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }