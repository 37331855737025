<template>
  <div>
    <van-nav-bar left-arrow :title="$t('tbm.考勤详情')" fixed :right-text="showDate" @click-left="back"> </van-nav-bar>
    <div style="padding-top:46px;" class="selfServiceDetail">
      <p :style="{ color: showAbnormal ? 'rgb(67, 120, 190)' : '#646566' }" @click="handleAbnormal">
        <i class="iconfont icon-shaixuan" style="margin-right:10px" />{{ $t('tbm.只看异常') }}
      </p>
      <div style="margin-top: 40px;">
        <div v-for="(item, index) in dataList" :key="index">
          <div>
            <p>
              <label>{{ $t('tbm.日期') }}</label>
              <span>{{ item.dateDetail }}</span>
            </p>
            <p>
              <label>{{ $t('tbm.签到时间') }}</label>
              <span>{{ item.signStartTime || '--' }}</span>
            </p>
            <p>
              <label>{{ $t('tbm.签退时间') }}</label>
              <span>{{
                item.signStartTime == item.signEndTime && item.signStartTime ? '--' : item.signEndTime || '--'
              }}</span>
            </p>
            <div v-if="currentDate == item.dateDetail">
              <p>
                <label>{{ $t('tbm.状态') }}</label>
                <span
                  :style="{
                    color:
                      item.dataFlag &&
                      (item.dataFlag.indexOf('迟到') != -1 ||
                        item.dataFlag.indexOf('早退') != -1 ||
                        item.dataFlag.indexOf('未签') != -1)
                        ? 'rgb(240, 45, 45)'
                        : ''
                  }"
                >
                  {{ strReplace(item.dataFlag) }}
                </span>
              </p>
            </div>
            <div v-if="currentDate != item.dateDetail">
              <p>
                <label>{{ $t('tbm.状态') }}</label>
                <span
                  :style="{
                    color:
                      item.dataFlag &&
                      (item.dataFlag.indexOf('迟到') != -1 ||
                        item.dataFlag.indexOf('早退') != -1 ||
                        item.dataFlag.indexOf('未签') != -1)
                        ? 'rgb(240, 45, 45)'
                        : ''
                  }"
                >
                  {{ item.dataFlag }}
                </span>
              </p>
            </div>
          </div>
          <div v-if="currentTime > item.dateDetail">
            <p
              @click="goRouter(item)"
              :style="{
                color:
                  (!item.signStartTime && !item.signEndTime) || (item.dataFlag && item.dataFlag.indexOf('休息') != -1)
                    ? 'rgb(153, 153, 153)'
                    : 'rgb(67, 120, 190)',
                borderColor:
                  (!item.signStartTime && !item.signEndTime) || (item.dataFlag && item.dataFlag.indexOf('休息') != -1)
                    ? 'rgb(153, 153, 153)'
                    : 'rgb(67, 120, 190)'
              }"
            >{{ $t('tbm.打卡详情') }}
            </p>
            <p v-if="item.attendanceResult !== '0' && item.dataFlag !== '0'" @click="showUnusual = true">{{ $t('tbm.异常处理') }}</p>
            <van-popup v-model="showUnusual" position="bottom">
              <van-picker
                show-toolbar
                :columns="unusualList"
                @confirm="onUnusualListConfirm"
                @cancel="showUnusual = false"
              />
            </van-popup>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      period: '',
      where: [],
      data: [],
      showDate: '',
      showAbnormal: false,
      dataList: [],
      showUnusual: false,
      unusualList: [
        {
          text: '请假申请',
          value: 'tbm/applyHoliday/applyHolidayEdit'
        },
        {
          text: '公出申请',
          value: 'tbm/applyOfficeout/applyOfficeoutEdit'
        },
        {
          text: '异常申请',
          value: 'tbm/applyUnusual/applyUnusualEdit'
        }
      ],
      currentTime: ''
    };
  },
  // methods: {
  //   // strReplace(value) {
  //   //   var rvalue = '';
  //   //   if (value) {
  //   //     rvalue = value.replace(',早退', '');
  //   //   }
  //   //   return rvalue;
  //   //   // strReplace(message)
  //   //   {
  //   //     if (!message || JSON.stringify(message) == '{}') {
  //   //       return '';
  //   //     } else {
  //   //       message = message.replace(',早退', '');
  //   //       return message.replace('早退', '');
  //   //     }
  //   //   }
  //   // },
  //   back() {
  //     this.$router.push({
  //       name: 'tbm/selfService/selfServiceList',
  //       params: {
  //         period: this.period
  //       }
  //     });
  //   },
  //   goRouter(item) {
  //     if ((!item.signStartTime && !item.signEndTime) || (item.dataFlag && item.dataFlag.indexOf('休息') != -1)) {
  //     } else {
  //       this.$router.push({
  //         name: 'tbm/selfService/selfServicePunch',
  //         query: {
  //           showDate: this.showDate,
  //           dateDetail: item.dateDetail
  //         }
  //       });
  //     }
  //   },
  //   onUnusualListConfirm(value) {
  //     this.showUnusual = false;
  //     this.$router.push({
  //       name: value.value
  //     });
  //   },
  //   init() {
  //     this.$myHttp({
  //       method: 'POST',
  //       url: '/hrtbm/summaryCycle/detail',
  //       data: {
  //         where: this.where
  //       }
  //     }).then(res => {
  //       if (res.data.rows) {
  //         this.data = res.data.rows;
  //         this.data.map(item => {
  //           if (item.dateDetail) {
  //             item.dateDetail = item.dateDetail.split(' ')[0];
  //           }
  //           if (item.signStartTime) {
  //             item.signStartTime = item.signStartTime.split(' ')[1];
  //           }
  //           if (item.signEndTime) {
  //             item.signEndTime = item.signEndTime.split(' ')[1];
  //           }
  //         });
  //         this.dataList = this.data.concat();
  //       } else {
  //         this.data = [];
  //         this.dataList = [];
  //       }
  //     });
  //   },
  //   handleAbnormal() {
  //     this.dataList = [];
  //     if (this.showAbnormal) {
  //       this.showAbnormal = false;
  //       this.dataList = this.dataList.concat(this.data);
  //     } else {
  //       this.showAbnormal = true;
  //       this.data.map(item => {
  //         if (item.attendanceResult !== '0' && item.dataFlag !== '0') {
  //           if (item.dateDetail <= this.currentTime) {
  //             this.dataList.push(item);
  //           }
  //         }
  //       });
  //     }
  //   },
  //   format(date, fmt) {
  //     let o = {
  //       'M+': date.getMonth() + 1, //月份
  //       'd+': date.getDate(), //日
  //       'H+': date.getHours(), //小时
  //       'm+': date.getMinutes(), //分
  //       's+': date.getSeconds(), //秒
  //       'q+': Math.floor((date.getMonth() + 3) / 3), //季度
  //       S: date.getMilliseconds() //毫秒
  //     };
  //     if (/(y+)/.test(fmt)) fmt = fmt.replace(RegExp.$1, (date.getFullYear() + '').substr(4 - RegExp.$1.length));
  //     for (let k in o)
  //       if (new RegExp('(' + k + ')').test(fmt))
  //         fmt = fmt.replace(RegExp.$1, RegExp.$1.length == 1 ? o[k] : ('00' + o[k]).substr(('' + o[k]).length));
  //     return fmt;
  //   }
  // },
  created() {
    let userId = JSON.parse(localStorage.getItem('userInfo')).id;
    this.period = this.$route.query.period;
    this.showDate = this.$route.query.showDate;
    this.where = [];
    if (userId) {
      this.where.push({ field: 'pkUser', opt: '=', value: userId, assemble: 'and' });
    }
    if (this.period) {
      this.where.push({ field: 'period', opt: '=', value: this.period, assemble: 'and' });
    }
    if (JSON.stringify(this.where) === '[]') {
      return false;
    }
    this.init();
    this.currentTime = this.format(new Date(), 'yyyy-MM-dd HH:mm:ss');
    this.currentDate = this.format(new Date(), 'yyyy-MM-dd');
  }
};
</script>
<style scoped>
.selfServiceDetail {
  padding: 10px;
}

.selfServiceDetail > p {
  padding: 10px 0;
  color: rgb(67, 120, 190);
  display: flex;
  align-items: center;
  position: fixed;
  width: 100%;
  background: #eeeeee;
}

.selfServiceDetail > div > div {
  display: flex;
  justify-content: space-between;
  padding-right: 10px;
  background: #fff;
  margin-bottom: 10px;
}

.selfServiceDetail > div > div > div:nth-child(1) p {
  display: flex;
  padding: 10px;
}

.selfServiceDetail > div > div > div:nth-child(1) p label {
  width: 75px;
  text-align: right;
  margin-right: 10px;
  color: #646566;
  flex-shrink: 0;
}

.selfServiceDetail > div > div > div:nth-child(2) p {
  font-size: 16px;
  padding: 5px 10px;
  border-radius: 20px;
}

.selfServiceDetail > div > div > div:nth-child(2) {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}

.selfServiceDetail > div > div > div:nth-child(2) p:nth-child(1) {
  color: rgb(67, 120, 190);
  border: 1px solid rgb(67, 120, 190);
  margin-bottom: 10px;
}

.selfServiceDetail > div > div > div:nth-child(2) p:nth-child(2) {
  color: #fff;
  background-color: rgba(234, 105, 105, 1);
  margin-bottom: 10px;
}
</style>
